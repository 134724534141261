const pages = [
  {
    groupTitle: 'Web Apps',
    id: 'web-apps',
    pages: [
      {title: 'MixMaster', href: '/mixmaster/', isLocal: true, isNew: true},
      {title: 'SnapScape', href: '/snapscape/', isLocal: true}
    ]
  },
  {
    groupTitle: 'Professional',
    id: 'professional',
    pages: [
      {title: 'Michele Corley Skin Care', href: 'https://michelecorleyclinicalskincare.com/', isLocal: false},
      {title: 'Art Translated', href: 'https://arttranslated.netlify.app/', isLocal: false}
    ]
  },
  {
    groupTitle: 'Volunteer',
    id: 'volunteer',
    pages: [{title: 'The Rebobs', href: 'https://www.napavalleycompositecycling.com/', isLocal: false}]
  },

  {
    groupTitle: 'Node.JS',
    id: 'node-js',
    pages: [
      {
        title: 'QA Projects',
        href: 'https://replit.com/@kate-mills?path=folder/public',
        isLocal: false
      }
    ]
  },
  {
    groupTitle: 'On Social',
    id: 'on-social',
    pages: [
      {
        title: 'GitHub',
        href: 'https://github.com/kate-mills',
        isLocal: false
      },
      {
        title: 'LinkedIn',
        href: 'https://www.linkedin.com/in/kate-mills-napa/',
        isLocal: false
      }
    ]
  }
]

export default pages
